'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { AuthContainer } from '@/components';
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useForm } from 'react-hook-form';

import * as zod from 'zod';
import { LoadingButton } from '@mui/lab';
import { Login } from '@mui/icons-material';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@/contexts/AuthContext';
import { GetServerSideProps } from 'next';
import { withSSRGuest } from '@/modules/core/auth/utils/withSSRGuest';
import Head from 'next/head';

const loginFormSchema = zod.object({
  email: zod
    .string()
    .email('Informe o e-mail')
    .transform((email) => email.trim().toLowerCase()),
  password: zod.string().min(6, 'Informe a senha'),
});

type LoginFormData = zod.infer<typeof loginFormSchema>;

export default function SignIn() {
  const { signIn, isSignInSuccess, setIsSignInSuccess } =
    useContext(AuthContext);

  useEffect(() => {
    setIsSignInSuccess(false);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  async function handleSignIn(data: LoginFormData) {
    await signIn(data);
  }

  return (
    <>
      <Head>
        <title>Água Amigão | Login</title>
      </Head>
      <AuthContainer>
        <Card
          elevation={3}
          sx={{
            padding: 3,
            paddingTop: 0,
            width: 500,
            maxWidth: '100%',
            margin: '0 auto',
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Image
              src="/agua-amigao.png"
              alt="Água Amigão"
              width="300"
              height="200"
            />
            <Stack
              component="form"
              onSubmit={handleSubmit(handleSignIn)}
              spacing={3}
            >
              <Typography color="primary" variant="h5" fontWeight="bold">
                Água Amigão - Login
              </Typography>
              <TextField
                type="email"
                label="E-mail"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register('email')}
              />
              <TextField
                type="password"
                label="Senha"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register('password')}
              />
              <LoadingButton
                type="submit"
                loading={isSubmitting || isSignInSuccess}
                loadingPosition="start"
                startIcon={<Login />}
                fullWidth
                size="large"
                variant="contained"
              >
                Entrar
              </LoadingButton>
              <Divider />
              <Link href="/forgot-password">
                <Button variant="text" size="small" fullWidth>
                  Esqueceu a senha?
                </Button>
              </Link>
            </Stack>
          </Box>
        </Card>
      </AuthContainer>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = withSSRGuest(
  // eslint-disable-next-line no-unused-vars
  async (ctx) => {
    return {
      props: {},
    };
  }
);
